var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "pa-0 ma-0",
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "toolbar" } },
        [
          _c("v-toolbar-title", { staticClass: "ml-2 title" }, [
            _vm._v(" Statement Manager "),
          ]),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { vertical: "", inset: "" },
          }),
          _c(
            "v-row",
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { lg: "5" } },
                [
                  _c("v-text-field", {
                    staticClass: "ma-0 py-2",
                    attrs: {
                      placeholder: _vm.search.key
                        ? "Search By " + _vm.search.key.description
                        : "Select Search Field",
                      flat: "",
                      solo: "",
                      outlined: "",
                      dense: "",
                      height: "10px",
                      "hide-details": "",
                      "background-color": "input",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchStatements()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-inner",
                        fn: function () {
                          return [
                            _c("v-autocomplete", {
                              staticClass: "ma-0 pa-0",
                              attrs: {
                                placeholder: "Search By",
                                "append-icon": ":",
                                items: _vm.searchParams,
                                flat: "",
                                solo: "",
                                "item-text": "description",
                                "return-object": "",
                                "hide-details": "",
                                dense: "",
                              },
                              model: {
                                value: _vm.search.key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "key", $$v)
                                },
                                expression: "search.key",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.searching,
                                                disabled: _vm.buttonDisabled,
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.searched === false
                                                    ? _vm.searchStatements()
                                                    : _vm.clearSearch()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "white--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.searched
                                                        ? "mdi-close"
                                                        : "mdi-magnify"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.searched
                                        ? "Clear Search"
                                        : "Search Statements"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.search.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.search, "value", $$v)
                      },
                      expression: "search.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticStyle: {
                    display: "grid",
                    "justify-content": "flex-end",
                    "grid-auto-flow": "column",
                    "grid-column-gap": "12px",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function ($event) {
                          ;(_vm.component = "StatementModal"),
                            (_vm.modal = true)
                        },
                      },
                    },
                    [_vm._v(" New Statement ")]
                  ),
                  _vm._l(_vm.processButtons, function (process, key) {
                    return _c(
                      "v-btn",
                      {
                        key: key,
                        staticClass: "white--text",
                        attrs: {
                          color: _vm.getStatusColor(process.status),
                          loading:
                            _vm.process_loading ||
                            process.status === "RUNNING" ||
                            process.status === "UPLOADING",
                        },
                        on: {
                          click: function ($event) {
                            ;(_vm.component = process.component),
                              (_vm.modal = true),
                              _vm.updateProcessOnClick(process, "WAITING")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "loader",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { class: _vm.getLoader(process.status) },
                                    [
                                      _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.getLoaderIcon(process))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.getProcessButtonText(process)) + " "
                        ),
                      ]
                    )
                  }),
                  _vm.process_loading
                    ? _c("v-btn", {
                        staticClass: "white--text",
                        attrs: {
                          color: "#0c67a5",
                          loading: _vm.process_loading,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: false,
            expression: "false",
          },
        ],
        style: { position: "relative", "z-index": "1" },
        attrs: {
          dense: "",
          "items-per-page": _vm.pageSize,
          headers: _vm.headers,
          height: _vm.tableSize,
          items: _vm.statements,
          loading: _vm.loading,
          expanded: _vm.expanded,
          "show-expand": "",
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "single-expand": "",
          "item-key": "id",
          "fixed-header": "",
          "hide-default-footer": "",
          search: _vm.filter,
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function ($event) {
            _vm.pageSize = $event
          },
          "update:expanded": function ($event) {
            _vm.expanded = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.data-table-select",
              fn: function (ref) {
                var on = ref.on
                var props = ref.props
                return [
                  _c(
                    "v-simple-checkbox",
                    _vm._g(
                      _vm._b(
                        { attrs: { ripple: false, dark: "" } },
                        "v-simple-checkbox",
                        props,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
            {
              key: "item.status",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.status
                    ? _c(
                        "v-chip",
                        {
                          attrs: {
                            small: "",
                            dark: "",
                            color: _vm.getColor(item.status),
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                margin: "0 auto",
                                width: "70px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.status) + " ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.created_on",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.created_on
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDateTime(item.created_on)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.updated_on",
              fn: function (ref) {
                var item = ref.item
                return [
                  item && item.updated_on
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDateTime(item.updated_on)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.notes",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          ;(_vm.component = "StatementNotes"),
                            (_vm.selectedStatement = item),
                            (_vm.modal = true)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-comment-text"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.info",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.copyID(item.statement_id)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-information")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(item.statement_id))])]
                  ),
                ]
              },
            },
            {
              key: "item.actions",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "open-on-click": "",
                        transition: "slide-x-transition",
                        bottom: "",
                        right: "",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto",
                          attrs: { "max-width": "300", tile: "" },
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _c("v-subheader", [_vm._v("Actions")]),
                              _c(
                                "v-list-item-group",
                                { attrs: { color: "primary" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        disabled:
                                          item.status.toLowerCase() !==
                                          "incomplete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          ;(_vm.selectedStatement = item),
                                            (_vm.component = "ConfirmCancel"),
                                            (_vm.modal = true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-cancel "),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Cancel Statement"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        disabled:
                                          item.status.toLowerCase() !==
                                          "incomplete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          ;(_vm.selectedStatement = item),
                                            (_vm.component = "ConfirmRerun"),
                                            (_vm.modal = true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-run-fast "),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Re-run Statement"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "expanded-item",
              fn: function (ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { colspan: headers.length },
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          dense: "",
                          "items-per-page": _vm.pageSize,
                          headers: _vm.expandedHeaders,
                          items: item.postedBatches,
                          loading: _vm.loading,
                          "hide-default-footer": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.batch_info",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  item
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.copyID(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v(_vm._s(item.id))])]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.expanded,
          callback: function ($$v) {
            _vm.expanded = $$v
          },
          expression: "expanded",
        },
      }),
      _c("v-divider"),
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "end" } },
            [
              _c(
                "v-col",
                { staticStyle: { "max-width": "150px" }, attrs: { cols: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mt-8",
                    attrs: {
                      dense: "",
                      items: _vm.pageSizes,
                      label: "Items Per Page",
                      select: "",
                      "menu-props": {
                        top: true,
                        offsetY: true,
                        maxHeight: 500,
                      },
                    },
                    model: {
                      value: _vm.pageSize,
                      callback: function ($$v) {
                        _vm.pageSize = $$v
                      },
                      expression: "pageSize",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disablePrevious },
                  on: {
                    click: function ($event) {
                      return _vm.previous()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { icon: "", large: "", disabled: _vm.disableNext },
                  on: {
                    click: function ($event) {
                      return _vm.next()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modal
        ? _c(_vm.component, {
            tag: "Component",
            attrs: {
              value: _vm.modal,
              selectedStatement: _vm.selectedStatement,
              selected: _vm.selected,
              expanded: _vm.expanded,
            },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
              closeModal: _vm.closeModal,
              closeProcessModal: _vm.closeProcessModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }